import React from "react"

export default function HeaderMenuDemolition() 
{    
    return(
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item dropdown" key="hm_item_1" >
                    <a id="hm_mainopt" className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" data-target="dropdown-menu" aria-expanded="false" onClick={() => {document.getElementById('dropdownHeader').classList.toggle('show')}}>Find Pros</a>
                    <ul id="dropdownHeader" key="hms_item_1" className="dropdown-menu dropdown-menu-right" aria-labelledby="hm_mainopt">
                        <li key="hms_item_1_1"><a className="dropdown-item" href="/">All Demolition Contractors</a></li>
                        <li key="hms_item_1_2"><a className="dropdown-item" href="/barn-demolition">Barn Demolition</a></li>
                        <li key="hms_item_1_3"><a className="dropdown-item" href="/chimney-removal">Chimney Removal</a></li>
                        <li key="hms_item_1_4"><a class="dropdown-item" href="/commercial-demolition">Commercial Demolition</a></li>
                        <li key="hms_item_1_5"><a class="dropdown-item" href="/concrete-removal">Concrete Removal</a></li>
                        <li key="hms_item_1_6"><a class="dropdown-item" href="/deck-and-fence-removal">Deck and Fence Removal</a></li>
                        <li key="hms_item_1_7"><a class="dropdown-item" href="/house-and-garage-demolition">House and Garage Demolition</a></li>
                        <li key="hms_item_1_8"><a class="dropdown-item" href="/interior-demolition">Interior Demolition</a></li>
                        <li key="hms_item_1_9"><a class="dropdown-item" href="/mobile-home-demolition">Mobile Home Removal</a></li>
                        <li key="hms_item_2_0"><a class="dropdown-item" href="/pool-removal">Pool Removal</a></li>
                        <li key="hms_item_2_1"><a class="dropdown-item" href="/tank-removal">Tank Removal</a></li>
                    </ul>
                </li>
                <li className="nav-item" key="hm_item_2" >
                    <a className="nav-link" href="/blog" role="button" data-toggle="" data-target="dropdown-menu" aria-expanded="false">Tips & Advice</a>
                </li>
                <li className="nav-item" key="hm_item_3" >
                    <a className="nav-link" href="/pro/guide/demolition-marketing" role="button" data-toggle="" data-target="dropdown-menu" aria-expanded="false">Are You a Demolition Pro?</a>
                </li>
            </ul>
        </div>
    )
}