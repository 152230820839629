import React from "react"
import SocialsDemolition from "./social-demolition";

export default function FooterMenuDemolition()
{   
    return(
        <div className="row">
            <div key="fcol1" className="col-lg-4">
                <ul className="footer-list">

                    <div className="collapser">
                        Customers
                    </div>
                    
                    <div className="collapse show">
                        <div key="foot_1_1">                                  
                            <li>
                                <a className="front-link" href="/blog">Tips & Advice</a>
                            </li>                                                        
                        </div>  
                        <div key="foot_1_2">                                  
                            <li>
                                <a className="front-link" href="/blog/how-it-works">How It Works</a>
                            </li>                                                        
                        </div>  
                    </div>                                         
                </ul>
            </div>
            <div key="fcol2" className="col-lg-4">
                <ul className="footer-list">

                    <div className="collapser">
                        Pros
                    </div>
                    
                    <div className="collapse show">
                        <div key="foot_2_1">                                  
                            <li>
                                <a className="front-link" href="/pro/guide/leads-demolition-how-it-works-video">How It Works</a>
                            </li>                                                        
                        </div>  
                        <div key="foot_2_2">                                  
                            <li>
                                <a className="front-link" href="/pro/guide">Pro Guide</a>
                            </li>                                                        
                        </div> 
                        <div key="foot_2_3">                                  
                            <li>
                                <a className="front-link" href="/pro/guide/tags/success-stories">Success Stories</a>
                            </li>                                                        
                        </div> 
                    </div>                                         
                </ul>
            </div>
            <div key="fcol3" className="col-lg-4">
                <ul className="footer-list">

                    <div className="collapser">
                        Company
                    </div>
                    
                    <div className="collapse show">
                        <div key="foot_3_1">                                  
                            <li>
                                <a className="front-link" href="/about-contact.html">About</a>
                            </li>                                                        
                        </div>
                        <SocialsDemolition />
                    </div>                                         
                </ul>
            </div>  
        </div>
    )
}