import React from "react";
import FooterMenuDemolition from "./footer-menu-demolition";
//import loadable from '@loadable/component'

//const FooterMenuDumpster = loadable(() => import('./footer-menu-dumpster'))
export default function FooterDemolition() 
{  
    return (       
        <div className="container-fluid">
            <div className="container">
                <section className="footer-content">
                    <FooterMenuDemolition />
                </section>
                <section className="row">
                    <div className="col-md-12 privacy">
                        <h6>©Hometown Local 2021</h6>
                        <span> | </span>
                        <a className="front-link" href="/hometown-privacy-policy">Privacy</a>
                        <span> | </span>
                        <a className="front-link" href="/terms-and-conditions.html">Terms of Use</a> 
                    </div>
                </section>
            </div>
        </div>
    );
}